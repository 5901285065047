import { IWPContent } from '../interfaces/wp';

const WP_API: string = process.env.REACT_APP_WP_API!;

export const fetchWordpressData = async (
  type: string,
  section: string,
  skipCache: boolean
) => {
  const response: IWPContent = await fetch(
    `${WP_API}/${type}&per_page=100${
      skipCache ? `&timestamp=${Date.now()}` : ''
    }`
  ).then((res) => res.json());
  return response.filter((item) => {
    return item.custom_fields.type && item.custom_fields.type[0] === section;
  });
};

export const fetchWordpressPage = async (
  type: string,
  path: string,
  skipCache: boolean
) => {
  const response: IWPContent = await fetch(
    `${WP_API}/${type}&per_page=100${
      skipCache ? `&timestamp=${Date.now()}` : ''
    }`
  ).then((res) => res.json());
  return response.filter((item) => {
    return (
      item.custom_fields.url_slug && item.custom_fields.url_slug[0] === path
    );
  })[0];
};
