import React, { useState, useEffect } from 'react';
import { fetchWordpressPage } from '../utils/wordpress';
import { useLocation, Redirect } from 'react-router-dom';
import IframeResizer from 'iframe-resizer-react';
import Title from '../components/Title';
import CustomButton from '../components/CustomButton';
import MetaData from '../components/MetaData';

interface IWordpressProps {
  pageTitle: string;
}

const Wordpress: React.FC<IWordpressProps> = ({ pageTitle }) => {
  // path: is second element of /route/path
  const pathArr = useLocation().pathname.split('/');
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const breakpoint = 740; // mobile breakpoint
  const queryParam = useLocation().search;
  const [wpLink, setWpLink] = useState<string>();
  const [wpHeight, setWpHeight] = useState<number>();
  const [wpHeightMobile, setWpHeightMobile] = useState<number>();
  const [metaTitle, setMetaTitle] = useState<string>();
  const [metaDescription, setMetaDescription] = useState<string>();
  const [metaImage, setMetaImage] = useState<string>();
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const response = await fetchWordpressPage(
        'pages',
        pathArr[2],
        queryParam === '?skipCache=true'
      );
      if (response && response.link) {
        setWpLink(response.link);
      }

      if (response && response.custom_fields.page_height_desktop) {
        // tslint:disable-next-line: radix
        setWpHeight(parseInt(response.custom_fields.page_height_desktop[0]));
      } else {
        setWpHeight(2300); // default
      }
      if (response && response.custom_fields.page_height_mobile) {
        setWpHeightMobile(
          // tslint:disable-next-line: radix
          parseInt(response.custom_fields.page_height_mobile[0])
        );
      } else {
        setWpHeightMobile(3100); // default
      }
      if (
        response &&
        response.custom_fields.metaImage &&
        response.custom_fields.metaTitle &&
        response.custom_fields.metaDescription
      ) {
        setMetaDescription(response.custom_fields.metaDescription[0]);
        setMetaImage(response.custom_fields.metaImage[0]);
        setMetaTitle(response.custom_fields.metaTitle[0]);
      }
      if (
        !response &&
        pathArr[2] !==
          'constellation-agency-named-the-10th-fastest-growing-female-led-company-in-america-on-the-2020-inc.-5000-list-no.-65-overall' &&
        pathArr[2] !==
          'constellation-agencys-diana-lee-selected-for-competitive-ey-enterpreneurial-winning-women-class-of-2019'
      )
        setShouldRedirect(true);
    };
    fetchData();

    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [pathArr, queryParam]);

  return (
    <div className="wordpress_page_container">
      {pathArr[2] ===
      'constellation-agency-named-the-10th-fastest-growing-female-led-company-in-america-on-the-2020-inc.-5000-list-no.-65-overall' ? (
        <Redirect to="/news/constellation-agency-named-the-no.-65th-fastest-growing-private-company-in-america-on-the-2020-inc.-5000-list" />
      ) : pathArr[2] ===
        'constellation-agencys-diana-lee-selected-for-competitive-ey-enterpreneurial-winning-women-class-of-2019' ? (
        <Redirect to="/news/constellation-agency%E2%80%99s-diana-lee-selected-for-competitive-ey-entrepreneurial-winning-women-class-of-2019" />
      ) : (
        shouldRedirect && <Redirect to="/news" />
      )}
      {wpHeight && wpHeightMobile && (
        <div>
          {metaDescription && metaTitle && metaImage && (
            <MetaData
              title={metaTitle}
              description={metaDescription}
              image={metaImage}
            />
          )}
          <Title title={pageTitle} />
          <IframeResizer
            src={wpLink}
            style={{ width: '1px', minWidth: '100%' }}
            checkOrigin={false}
            log={false}
            heightCalculationMethod="max"
            minHeight={windowWidth <= breakpoint ? wpHeightMobile : wpHeight}
          />
          {pathArr[1] === 'careers' && (
            <div style={{ textAlign: 'center' }}>
              <CustomButton
                text="APPLY NOW"
                link="mailto:careers@constellationagency.com"
                styles={{
                  padding: '1% 2%',
                  margin: '5% auto'
                }}
                mail={true}
              />
            </div>
          )}
          {pathArr[1] === 'news' && (
            <div style={{ textAlign: 'center' }}>
              <CustomButton
                text="RETURN TO ALL ARTICLES"
                link="/news"
                styles={
                  windowWidth > breakpoint
                    ? {
                        padding: '1% 2%',
                        margin: '5% auto',
                        backgroundColor: '#31465E',
                        width: '500px'
                      }
                    : {
                        padding: '1% 2%',
                        margin: '5% auto',
                        backgroundColor: '#31465E',
                        fontSize: '17px'
                      }
                }
                mail={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Wordpress;
