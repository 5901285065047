import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import { routes } from './routes';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Switch>
        {routes.map((route, idx) => {
          if (route.exact && !route.legacy) {
            return (
              <Route
                exact={route.exact}
                path={route.path}
                component={route.component}
                key={idx}
              />
            );
          } else if (!route.exact && !route.legacy) {
            return (
              <Route
                exact={route.exact}
                path={route.path}
                key={idx}
                render={() => <route.component pageTitle={route.pageTitle!} />}
              />
            );
          }
          // legacy routes
          return (
            route.legacyRoutes &&
            route.legacyRoutes.map((legacy, index) => (
              <Route
                exact={true}
                path={legacy.replace('https://constellationagency.com', '')}
                key={index}
                component={() => {
                  window.location.href = `${legacy.replace(
                    'https://constellationagency.com',
                    'https://archive.constellationagency.com'
                  )}`;
                  return null;
                }}
              />
            ))
          );
        })}
        <Route
          path="/team"
          exact={true}
          component={() => <Redirect to="/about" />}
        />
        <Route
          path="/social-impact"
          exact={true}
          component={() => <Redirect to="/causes" />}
        />
        <Route
          path="/launchpad"
          exact={true}
          component={() => <Redirect to="/technology/launchpad" />}
        />
        <Route
          path="/paid/search"
          exact={true}
          component={() => <Redirect to="/digital-marketing" />}
        />
         <Route
          path="/paid/social"
          exact={true}
          component={() => <Redirect to="/digital-marketing" />}
        />
        <Route path="*" exact={true} component={() => <Redirect to="/" />} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
