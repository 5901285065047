import React from 'react';
import { Fade } from 'react-reveal';
import { IImages } from '../interfaces/general';
import './GridScrollAnimation.scss';

interface IGridScrollProps {
  column1: IImages;
  column2: IImages;
  column3: IImages;
}

const GridScrollAnimation: React.FC<IGridScrollProps> = ({
  column1,
  column2,
  column3
}) => {
  return (
    <div className="wrapper">
      <div className="grid_desktop">
        <div className="column">
          {column1.map((image, idx) => (
            <div key={idx}>
              <Fade left={true} duration={1500} delay={1000}>
                <img
                  src={image.fields.file.url}
                  alt={image.fields.title}
                  style={{ width: '100%' }}
                />
              </Fade>
            </div>
          ))}
        </div>
        <div className="column">
          {column2.map((image, idx) => (
            <div key={idx}>
              <Fade bottom={true} duration={1500} delay={1000}>
                <img
                  src={image.fields.file.url}
                  alt={image.fields.title}
                  style={{ width: '100%' }}
                />
              </Fade>
            </div>
          ))}
        </div>

        <div className="column">
          {column3.map((image, idx) => (
            <div key={idx}>
              <Fade right={true} duration={1500} delay={1000}>
                <img
                  src={image.fields.file.url}
                  alt={image.fields.title}
                  style={{ width: '100%' }}
                />
              </Fade>
            </div>
          ))}
        </div>
      </div>
      <div className="grid_mobile">
        <div className="column">
          {column1.slice(0, column1.length - 1).map((image, idx) => (
            <div key={idx}>
              <Fade left={true} duration={1500} delay={1000}>
                <img
                  src={image.fields.file.url}
                  alt={image.fields.title}
                  style={{ width: '100%' }}
                />
              </Fade>
            </div>
          ))}
          <div>
            <Fade left={true} duration={1500} delay={1000}>
              <img
                src={column3[0].fields.file.url}
                alt={column3[0].fields.title}
                style={{ width: '100%' }}
              />
            </Fade>
          </div>
          <div>
            <Fade left={true} duration={1500} delay={1000}>
              <img
                src={column3[1].fields.file.url}
                alt={column3[1].fields.title}
                style={{ width: '100%' }}
              />
            </Fade>
          </div>
        </div>
        <div className="column">
          {column2.map((image, idx) => (
            <div key={idx}>
              <Fade right={true} duration={1500} delay={1000}>
                <img
                  src={image.fields.file.url}
                  alt={image.fields.title}
                  style={{ width: '100%' }}
                />
              </Fade>
            </div>
          ))}
          <Fade left={true} duration={1500} delay={1000}>
            <img
              src={column1[column1.length - 1].fields.file.url}
              alt={column1[column1.length - 1].fields.title}
              style={{ width: '100%' }}
            />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default GridScrollAnimation;
