import React, { useState, useEffect } from 'react';
import { ITechnologyContent } from './../interfaces/technology';
import { getTechnologyEntry } from './../utils/contentful';
import Title from './../components/Title';
import Header1 from './../components/Header1';
import Paragraph from './../components/Paragraph';
import SassOfferingsTile from './../components/SaasOfferingsTile';
import MetaData from '../components/MetaData';

import './Technology.scss';

const Technology: React.FC = () => {
  const [content, setContent] = useState<ITechnologyContent>();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const breakpoint = 740; // mobile breakpoint

  useEffect(() => {
    window.scrollTo(0, 0);
    const getPageContent = async () => {
      const pageContent = await getTechnologyEntry();
      setContent(pageContent);
    };
    getPageContent();

    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <div>
      {content && (
        <div className="technology_page_container">
          <MetaData
            title={content.metaTitle}
            description={content.metaDescription}
            image={content.metaImage.fields.file.url}
          />
          <Title title="TECHNOLOGY" />
          <div className="first_paragraph">
            <Paragraph paragraph={content.paragraphOne} />
          </div>
          {windowWidth <= breakpoint ? (
            <div className="hero_image_container">
              <img src={content.heroImageMobile.fields.file.url} alt="" />
            </div>
          ) : (
            <div className="hero_image_container">
              <img src={content.heroImageDesktop.fields.file.url} alt="" />
            </div>
          )}
          <div className="second_paragraph">
            <Paragraph paragraph={content.paragraphTwo} />
          </div>
          <p className="tech_slogans">
            <span className="blue_text">{content.firstRowPartOne}</span>
            <span className="gray_text"> {content.firstRowPartTwo}</span>
            <span className="gray_text"> {content.secondRowPartOne}</span>
            <span className="blue_text"> {content.secondRowPartTwo}</span>
            <span className="gray_text"> {content.thirdRow}</span>
            <span className="blue_text"> {content.fourthRow}</span> <br />
            <span className="gray_text"> {content.fifthRowPartOne}</span>
            <span className="blue_text"> {content.fifthRowPartTwo}</span>
          </p>
          <div className="our_sass_offerings_section">
            <Header1 header="OUR SAAS" />
            <Header1 header="OFFERINGS" />
            {windowWidth <= breakpoint ? (
              <div>
                {content.sassBackgroundMobile.map((image, idx) => (
                  <div className="sass_info" key={idx}>
                    <SassOfferingsTile
                      backgroundImage={image.fields.file.url}
                      title={content.sassOfferings[idx].title}
                      text={content.sassOfferings[idx].text}
                      route={content.technologyRoutes[idx]}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {content.sassBackgroundImages.map((image, idx) => (
                  <div className="sass_info" key={idx}>
                    <SassOfferingsTile
                      backgroundImage={image.fields.file.url}
                      title={content.sassOfferings[idx].title}
                      text={content.sassOfferings[idx].text}
                      route={content.technologyRoutes[idx]}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="hero_image_bottom">
            <img src={content.heroBottomImage.fields.file.url} alt="" />
          </div>
          {/* 
            Here goes "More From Constellation"
            Should be a separate component
          */}
        </div>
      )}
    </div>
  );
};

export default Technology;
