import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Title from '../components/Title';
import Paragraph from './../components/Paragraph';
import JobPosting from '../components/JobPosting';
import { ICareersContent } from '../interfaces/careers';
import { getCareersEntry } from '../utils/contentful';
import { IWPContent } from '../interfaces/wp';
import { fetchWordpressData } from '../utils/wordpress';
import MetaData from '../components/MetaData';
import './Careers.scss';

const Careers: React.FC = () => {
  const [content, setContent] = useState<ICareersContent>();
  const [positions, setPositions] = useState<IWPContent>();
  const queryParam = useLocation().search;

  useEffect(() => {
    window.scrollTo(0, 0);
    const getPageContent = async () => {
      const pageContent = await getCareersEntry();
      setContent(pageContent);
    };
    getPageContent();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchWordpressData(
        'pages',
        'careers',
        queryParam === '?skipCache=true'
      );
      setPositions(res);
    };
    fetchData();
  }, [queryParam]);
  return (
    <div>
      {content && (
        <div className="careers_page_container">
          <MetaData
            title={content.metaTitle}
            description={content.metaDescription}
            image={content.metaImage.fields.file.url}
          />
          <Title title="CAREERS" />
          <div className="careers_content_wrapper">
            <div className="subtitle_wrapper">
              <Paragraph paragraph={content.subtitle} />
            </div>
            <div className="benefits_section">
              {content.benefitIcons.map((icon, idx) => (
                <div className="single_benefit" key={idx}>
                  <div className="icon_wrapper">
                    <img
                      src={icon.fields.file.url}
                      alt=""
                      className="benefit_icon"
                    />
                  </div>
                  <p className="benefit_title">
                    {content.benefitsDescription[idx].title}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="job_posting_wrapper">
            {positions &&
              positions.map((position, idx) => (
                <JobPosting
                  key={idx}
                  title={position.custom_fields.title[0]}
                  urlSlug={position.custom_fields.url_slug[0]}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Careers;
