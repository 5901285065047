import React, { useState, useEffect } from 'react';
import { IHomeContent } from './../interfaces/home';
import { getHomeEntry } from './../utils/contentful';
import ReactPageScroller from 'react-page-scroller';
import Slide from './../components/GenericHomepageSlides';
import Footer from './../components/Footer';
import MetaData from '../components/MetaData';

const Home: React.FC = () => {
  const [content, setContent] = useState<IHomeContent>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const breakpoint = 740; // mobile breakpoint

  useEffect(() => {
    const getPageContent = async () => {
      const pageContent = await getHomeEntry();
      setContent(pageContent);
    };
    getPageContent();

    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <div>
      {content && (
        <div className="home_page_container">
          <MetaData
            title={content.metaTitle}
            description={content.metaDescription}
            image={content.metaImage.fields.file.url}
          />
          <ReactPageScroller
            animationTimer={1500}
            customPageNumber={currentPage}
            pageOnChange={(num: number) => setCurrentPage(num)}
          >
            {/* Component does not work using map, hence one by one */}
            <Slide
              slideInfo={content.slidesInfo[0]}
              videoSlide={
                windowWidth <= breakpoint
                  ? content.videoSlidesMobile[0]
                  : content.videoSlides[0]
              }
              displayOverlay={false}
              textAnimation={content.videoSlidesAnimation[0]}
              setCurrentPage={setCurrentPage}
              stillImage={content.stillImages[0]}
            />
            <Slide
              slideInfo={content.slidesInfo[1]}
              videoSlide={
                windowWidth <= breakpoint
                  ? content.videoSlidesMobile[1]
                  : content.videoSlides[1]
              }
              displayOverlay={true}
              textAnimation={content.videoSlidesAnimation[1]}
              setCurrentPage={setCurrentPage}
              stillImage={content.stillImages[1]}
            />
            <Slide
              slideInfo={content.slidesInfo[2]}
              videoSlide={
                windowWidth <= breakpoint
                  ? content.videoSlidesMobile[2]
                  : content.videoSlides[2]
              }
              displayOverlay={true}
              textAnimation={content.videoSlidesAnimation[2]}
              setCurrentPage={setCurrentPage}
              stillImage={content.stillImages[2]}
            />
            <Slide
              slideInfo={content.slidesInfo[3]}
              videoSlide={
                windowWidth <= breakpoint
                  ? content.videoSlidesMobile[3]
                  : content.videoSlides[3]
              }
              displayOverlay={true}
              textAnimation={content.videoSlidesAnimation[3]}
              setCurrentPage={setCurrentPage}
              stillImage={content.stillImages[3]}
            />
            <Slide
              slideInfo={content.slidesInfo[4]}
              videoSlide={
                windowWidth <= breakpoint
                  ? content.videoSlidesMobile[4]
                  : content.videoSlides[4]
              }
              displayOverlay={true}
              textAnimation={content.videoSlidesAnimation[4]}
              setCurrentPage={setCurrentPage}
              stillImage={content.stillImages[4]}
            />
            {/* Commenting as these pages will be delayed for launch */}
            {/* <Slide
              slideInfo={content.slidesInfo[5]}
              videoSlide={
                windowWidth <= breakpoint
                  ? content.videoSlidesMobile[5]
                  : content.videoSlides[5]
              }
              displayOverlay={true}
            />
            */}
            <Slide
              slideInfo={content.slidesInfo[6]}
              videoSlide={
                windowWidth <= breakpoint
                  ? content.videoSlidesMobile[6]
                  : content.videoSlides[6]
              }
              displayOverlay={true}
              textAnimation={content.videoSlidesAnimation[6]}
              setCurrentPage={setCurrentPage}
              stillImage={content.stillImages[6]}
            />
            <Slide
              slideInfo={content.slidesInfo[7]}
              videoSlide={
                windowWidth <= breakpoint
                  ? content.videoSlidesMobile[7]
                  : content.videoSlides[7]
              }
              displayOverlay={true}
              textAnimation={content.videoSlidesAnimation[5]}
              setCurrentPage={setCurrentPage}
              stillImage={content.stillImages[5]}
            />
            <Footer shouldDisplay={true} updateHomeSlide={setCurrentPage} />
          </ReactPageScroller>
        </div>
      )}
    </div>
  );
};

export default Home;
