import React from 'react';
import Header1 from '../components/Header1';
import { IImages } from '../interfaces/general';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { ITechnologyTiles } from '../interfaces/general';

import './MoreTechnologyTile.scss';

interface IMoreTechnologyTileProps {
  images: IImages;
  info: ITechnologyTiles;
}

const MoreTechnologyTile: React.FC<IMoreTechnologyTileProps> = ({
  images,
  info
}) => {
  return (
    <div className="more_technology_tile_container">
      <Header1
        header="EXPLORE SAAS OFFERINGS"
        style={{ textAlign: 'center' }}
      />

      <div className="technology_tiles_container">
        {images.map((image, idx) => (
          <div className="tile" key={idx}>
            <Link to={`${info[idx].link}`}>
              <img src={image.fields.file.url} alt="" />
              <div className="info">
                <div>{info[idx].title}</div>
                <div className="description">{info[idx].description}</div>
                <div className="redirect">
                  <FontAwesomeIcon
                    icon={faArrowAltCircleRight}
                    size="1x"
                    className="icon"
                    style={{ color: '#000', marginRight: '4%' }}
                  />
                  More Info
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MoreTechnologyTile;
