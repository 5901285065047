import React, { useState, useEffect } from 'react';
import GoogleMaps from './../components/GoogleMaps';
import { IContactContent } from './../interfaces/contact';
import { getContactEntry } from './../utils/contentful';

import Title from './../components/Title';
import CustomButtom from './../components/CustomButton';
import MetaData from '../components/MetaData';
import './Contact.scss';

const AWS_API: string = process.env.REACT_APP_AWS_API!;

const Contact: React.FC = () => {
  const [content, setContent] = useState<IContactContent>();
  const [userEmail, setUserEmail] = useState<string>();
  const [userMessage, setUserMessage] = useState<string>();
  const [userName, setUserName] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getPageContent = async () => {
      const pageContent = await getContactEntry();
      setContent(pageContent);
    };
    getPageContent();
  }, []);

  const handleOnChange = (value: string, type: string) => {
    if (type === 'name') {
      setUserName(value);
    } else if (type === 'email') {
      setUserEmail(value);
    } else if (type === 'message') {
      setUserMessage(value);
    }
  };

  const onSubmit = async () => {
    try {
      await fetch(`${AWS_API}/send`, {
        method: 'POST',
        body: JSON.stringify({
          name: userName,
          email: userEmail,
          message: userMessage
        }),
        headers: { 'Content-Type': 'application/json' }
      });

      setUserEmail('');
      setUserMessage('');
      setUserName('');
    } catch (err) {
      setErrorMessage(
        'Unable to process request at this time. Try again later'
      );
    }
  };

  return (
    <div>
      {content && (
        <MetaData
          title={content.metaTitle}
          description={content.metaDescription}
          image={content.metaImage.fields.file.url}
        />
      )}
      <Title title="CONTACT" />
      <div className="contact_page_container">
        <div className="user_input_section">
          <div className="page_title">Get in touch</div>
          <div className="input_container">
            <form>
              <div className="personal_input">
                <div>
                  <input
                    type="text"
                    placeholder="Name*"
                    required={true}
                    value={userName}
                    onChange={(event) =>
                      handleOnChange(event.target.value, 'name')
                    }
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Email*"
                    required={true}
                    value={userEmail}
                    onChange={(event) =>
                      handleOnChange(event.target.value, 'email')
                    }
                  />
                </div>
              </div>
              <div className="message_input">
                <textarea
                  rows={4}
                  placeholder="Message*"
                  className="textarea"
                  required={true}
                  value={userMessage}
                  onChange={(event) =>
                    handleOnChange(event.target.value, 'message')
                  }
                />
              </div>
            </form>
          </div>
          <div className="button">
            <CustomButtom
              text="SUBMIT"
              link="/"
              type="submit"
              onClick={onSubmit}
              styles={
                userEmail && userMessage && userName
                  ? { padding: '0.5% 4%' }
                  : {
                      padding: '0.5% 4%',
                      cursor: 'not-allowed',
                      pointerEvents: 'none',
                      color: '#000',
                      backgroundColor: '#EBEBEB'
                    }
              }
            />
            {errorMessage}
          </div>
        </div>
        {content && (
          <div className="info_section">
            <div className="info_image_container">
              <img src={content.infoImage.fields.file.url} alt="" />
            </div>
            <div className="info_text_container">
              {content.infoSection.map(({ title, phone, email }, idx) => (
                <div className="info_tile" key={idx}>
                  <div className="info_title">{title}</div>
                  <a href={`tel:+1-${phone}`}>
                    {' '}
                    <div className="info_phone">{phone}</div>
                  </a>
                  <a href={`mailto:${email}`}>
                    <div className="info_email">{email}</div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}
        {content &&
          content.officeLocations.map((office, idx) => (
            <div key={idx} className="office_location_container">
              <div className="office_location">
                <div className="location">{office.location}</div>
                <div>{office.streetAddress}</div>
                <div>{office.stateAndZipCode}</div>
              </div>
              <GoogleMaps
                latitude={office.latitude}
                longitude={office.longitud}
                label={office.streetAddress}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Contact;
