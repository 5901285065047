import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { INavBarContent } from './../interfaces/navbar';
import { getNavBarEntry } from './../utils/contentful';

import './NavigationMenu.scss';

interface INavigationMenu {
  setIsMenuClicked: (isMenuClicked: boolean) => void;
  display: boolean;
}

const NavigationMenu: React.FC<INavigationMenu> = ({
  setIsMenuClicked,
  display
}) => {
  const [content, setContent] = useState<INavBarContent>();

  useEffect(() => {
    const getPageContent = async () => {
      const pageContent = await getNavBarEntry();
      setContent(pageContent);
    };
    getPageContent();
  }, []);

  return (
    <div className="navigation_menu_section">
      {content && (
        <div
          className={`navigation_menu_container ${
            display ? 'display' : 'hide'
          }`}
        >
          <div className="menu_header">
            <div className="constellation_logo_container">
              <Link to="/" onClick={() => setIsMenuClicked(false)}>
                <img
                  src={content.constellationLogoWhite.fields.file.url}
                  alt=""
                />
              </Link>
            </div>
            <div
              className="go_back_button"
              onClick={() => setIsMenuClicked(false)}
            >
              <div className="back_button_container">
                <img
                  src={content.backButton.fields.file.url}
                  alt="back button arrow"
                />
              </div>
            </div>
          </div>
          <div className="menu_content_container">
            {content.menuFields.map((field, idx) => (
              <div className="field_text" key={idx}>
                <Link
                  to={content.menuRoutes[idx]}
                  onClick={() => setIsMenuClicked(false)}
                >
                  {field}
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavigationMenu;
