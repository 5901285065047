import React from 'react';

import './Title.scss';

export interface ITitleProps {
  title: string;
}

const Title: React.FC<ITitleProps> = ({ title }) => {
  return (
    <div className="title_container">
      <h1>{title}</h1>
      <div className="title_underline" />
    </div>
  );
};
export default Title;
