import React, { useState, useEffect } from 'react';
import { IVendorDataManagerContent } from './../interfaces/vendor-data-manager';
import { getVendorDataManagerEntry } from './../utils/contentful';

import Title from './../components/Title';
import Paragraph from './../components/Paragraph';
import FeaturesContainer from './../components/FeaturesContainer';
import HeroFooter from '../components/TechnologyHeroFooter';
import MoreTechnologyTiles from '../components/MoreTechnologyTile';
import MetaData from '../components/MetaData';
import './VendorDataManager.scss';

const VendorDataManager: React.FC = () => {
  const [content, setContent] = useState<IVendorDataManagerContent>();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const breakpoint = 740; // mobile breakpoint

  useEffect(() => {
    window.scrollTo(0, 0);
    const getPageContent = async () => {
      const pageContent = await getVendorDataManagerEntry();
      setContent(pageContent);
    };
    getPageContent();

    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <div>
      {content && (
        <div className="vendor_data_manager_container">
          <MetaData
            title={content.metaTitle}
            description={content.metaDescription}
            image={content.metaImage.fields.file.url}
          />
          <Title title="VENDOR DATA MANAGER" />
          <div className="paragraph_container">
            <Paragraph paragraph={content.paragraphOne} />
          </div>
          <FeaturesContainer
            title="FEATURES"
            icons={content.featureIcons}
            iconsDescription={content.featuresInfo}
          />
          <HeroFooter
            image={
              windowWidth <= breakpoint
                ? content.heroFooterMobile
                : content.heroFooterDesktop
            }
            text={content.heroFooterText}
          />
          <MoreTechnologyTiles
            images={content.relatedTechnology}
            info={content.relatedTechnologyInfo}
          />
        </div>
      )}
    </div>
  );
};

export default VendorDataManager;
