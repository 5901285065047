import React from 'react';
import { Link } from 'react-router-dom';
import infoIcon from './../static/svgs/info-icon.svg';
import './LeadershipTile.scss';

export interface ILeadershipTileProps {
  image: string;
  name: string;
  position: string;
  index: number;
}

const LeadershipTile: React.FC<ILeadershipTileProps> = ({
  image,
  name,
  position,
  index
}) => {
  return (
    <div className="leadership_container">
      <div className="leadership_image_container">
        <img src={image} alt="" />
        <div className="leadership_info">
          <div className="leadership_name">{name}</div>
          <div className="leadership_position">{position}</div>
          <div
            className={`leadership_more_info ${
              name !== 'Matt Woodruff' &&
              name !== 'David Johnson' &&
              'add_padding'
            }`}
          >
            <div className="icon_container">
              <img src={infoIcon} alt="information icon" />
            </div>
            <Link
              to={{
                pathname: '/leadership',
                state: { leader: { name }, position, index }
              }}
            >
              More info
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadershipTile;
