import React, { useState, useEffect } from 'react';
import { useLocation, Redirect, Link } from 'react-router-dom';
import { ILeadershipContent } from './../interfaces/leadership';
import { getLeadershipEntry } from './../utils/contentful';
import Title from '../components/Title';

import './Leadership.scss';

interface ILeader {
  leader: string;
  position: string;
  index: number;
}

const Leadership: React.FC = () => {
  const [content, setContent] = useState<ILeadershipContent>();
  const leaderObj = useLocation<ILeader>().state;

  useEffect(() => {
    window.scrollTo(0, 0);
    const getPageContent = async () => {
      const pageContent = await getLeadershipEntry();
      setContent(pageContent);
    };
    getPageContent();
  }, []);

  return (
    <div>
      {content && leaderObj ? (
        <div className="leadership_page_container">
          <Title title="LEADERSHIP" />
          <div className="leader_content">
            <div className="leader_name">
              {content.leadershipInfo[leaderObj.index].name}
            </div>
            <div className="leader_title">
              {content.leadershipInfo[leaderObj.index].title}
            </div>
            <div className="leader_image_container">
              <img
                src={content.leadershipPicture[leaderObj.index].fields.file.url}
                alt=""
              />
            </div>
            {content.leadershipInfo[leaderObj.index].info.map((info, idx) => (
              <div className="leader_paragraph" key={idx}>
                {info}
              </div>
            ))}
            <div className="line_separator" />
          </div>
          <div className="back_link_container">
            <div className="line_separator" />
            <div className="back_link">
              <Link to="/about#leadership">Back to About Us</Link>
            </div>
          </div>
        </div>
      ) : (
        !leaderObj && <Redirect to="/about" />
      )}
    </div>
  );
};

export default Leadership;
