import React, { useEffect, useState } from 'react';
import { IWPContent } from '../interfaces/wp';
import { useLocation } from 'react-router-dom';
import { fetchWordpressData } from '../utils/wordpress';
import MetaData from '../components/MetaData';

import Title from '../components/Title';
import NewsTile from '../components/NewsTile';

import './News.scss';

const News: React.FC = () => {
  const [newsContent, setNewsContent] = useState<IWPContent>();
  const queryParam = useLocation().search;

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const response = await fetchWordpressData(
        'pages',
        'news',
        queryParam === '?skipCache=true'
      );
      setNewsContent(
        response.sort(
          (a, b) =>
            a.custom_fields.date_published &&
            b.custom_fields.date_published &&
            new Date(b.custom_fields.date_published[0]).getTime() -
              new Date(a.custom_fields.date_published[0]).getTime()
        )
      );
    };
    fetchData();
  }, [queryParam]);

  return (
    <div>
      <MetaData
        title="News"
        description="Stay in touch with Constellation Agency's News"
        image="https://constellationinc.s3.amazonaws.com/Website/Web+Content/Constellation+Agency/2020/Artboard+1.jpg"
      />
      <Title title="NEWS" />
      {newsContent && (
        <div className="news_tiles_container">
          {newsContent.map(
            (
              { custom_fields: { image, title, url_slug, date_published } },
              idx
            ) => (
              <div key={idx} className="news_tile">
                <NewsTile
                  image={image[0]}
                  title={title[0]}
                  urlSlug={url_slug[0]}
                  datePublished={date_published[0]}
                />
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default News;
