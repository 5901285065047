import React, { useState, useEffect } from 'react';
import { ILaunchpadContent } from './../interfaces/launchpad';
import { getLaunchpadEntry } from './../utils/contentful';

import Title from './../components/Title';
import Paragraph from './../components/Paragraph';
import CustomButton from './../components/CustomButton';
import HeroFooter from '../components/TechnologyHeroFooter';
import MoreTechnologyTiles from '../components/MoreTechnologyTile';
import MetaData from '../components/MetaData';
import './Launchpad.scss';

const Launchpad: React.FC = () => {
  const [content, setContent] = useState<ILaunchpadContent>();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const breakpoint = 740; // mobile breakpoint

  useEffect(() => {
    window.scrollTo(0, 0);
    const getPageContent = async () => {
      const pageContent = await getLaunchpadEntry();
      setContent(pageContent);
    };
    getPageContent();

    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <div>
      {content && (
        <div>
          <Title title="LAUNCHPAD" />
          <MetaData
            title={content.metaTitle}
            description={content.metaDescription}
            image={content.metaImage.fields.file.url}
          />
          <div className="launchpad_page_container">
            <div className="paragraph_container">
              <Paragraph paragraph={content.paragraphOne} />
              <Paragraph paragraph={content.paragraphTwo} />
              <div className="button_container">
                <CustomButton text="REQUEST DEMO" link="/contact" />
              </div>
            </div>
            <div className="launchpad_info_section">
              <div className="launchpad_block_one">
                <div className="ad_on_demand">
                  <div className="title">
                    <Paragraph
                      paragraph={content.launchpadInfo[0].title}
                      styles={{ color: '#ffffff' }}
                    />
                  </div>
                  <div className="description">
                    {content.launchpadInfo[0].description[0]}
                  </div>
                  <div className="image_container">
                    <img
                      src={content.launchpadImages[0].fields.file.url}
                      alt=""
                    />
                  </div>
                </div>
                <div className="uploaded_content">
                  <div className="info">
                    <div className="title">
                      <Paragraph paragraph={content.launchpadInfo[1].title} />
                    </div>
                    <div className="description">
                      {content.launchpadInfo[1].description}
                    </div>
                  </div>
                  <div className="image_container">
                    <img
                      src={content.launchpadImages[1].fields.file.url}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="launchpad_block_two">
                <div className="paid_and_audience_container">
                  <div className="paid_organic_container">
                    <div className="info">
                      <div className="title">
                        <Paragraph
                          paragraph={content.launchpadInfo[2].title}
                          styles={
                            windowWidth <= breakpoint
                              ? { color: '#ffffff' }
                              : { color: '#000000' }
                          }
                        />
                      </div>
                      {content.launchpadInfo[2].description.map(
                        (description, idx) => (
                          <div className="description" key={idx}>
                            {description}
                          </div>
                        )
                      )}
                    </div>
                    <div className="image_container">
                      <img
                        src={content.launchpadImages[2].fields.file.url}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="advanced_audience_targeting_container">
                    <div className="info">
                      <div className="title">
                        <Paragraph
                          paragraph={content.launchpadInfo[4].title}
                          styles={
                            windowWidth <= breakpoint
                              ? { color: '#000000' }
                              : { color: '#ffffff' }
                          }
                        />
                      </div>
                      <div className="description">
                        {content.launchpadInfo[4].description[0]}
                      </div>
                    </div>
                    <div className="image_container">
                      <img
                        src={content.launchpadImages[4].fields.file.url}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="easy_to_use_container">
                  <div className="title">
                    <Paragraph
                      paragraph={content.launchpadInfo[3].title}
                      styles={{ color: '#ffffff' }}
                    />
                  </div>
                  <div className="description">
                    {content.launchpadInfo[3].description[0]}
                  </div>
                  <div className="image_container">
                    <img
                      src={content.launchpadImages[3].fields.file.url}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="launchpad_block_three">
                <div className="hybrid_approach_container">
                  <div className="image_container">
                    <img
                      src={content.launchpadImages[5].fields.file.url}
                      alt=""
                    />
                  </div>
                  <div className="info">
                    <div className="title">
                      <Paragraph paragraph={content.launchpadInfo[5].title} />
                    </div>
                    <div className="description">
                      {content.launchpadInfo[5].description[0]}
                    </div>
                  </div>
                </div>
                <div className="creative_built_and_shared_content_container">
                  <div className="creative_and_built_container">
                    <div className="creative_library_container">
                      <div className="info">
                        <div className="title">
                          <Paragraph
                            paragraph={content.launchpadInfo[6].title}
                            styles={{ color: '#ffffff' }}
                          />
                        </div>
                        <div className="description">
                          {content.launchpadInfo[6].description}
                        </div>
                      </div>
                      <div className="image_container">
                        <img
                          src={content.launchpadImages[6].fields.file.url}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="built_in_disclaimers_container">
                      <div className="image_container">
                        <img
                          src={content.launchpadImages[7].fields.file.url}
                          alt=""
                        />
                      </div>
                      <div className="info">
                        <div className="title">
                          <Paragraph
                            paragraph={content.launchpadInfo[7].title}
                            styles={{ color: '#ffffff' }}
                          />
                        </div>
                        <div className="description">
                          {content.launchpadInfo[7].description[0]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="shared_content_creation_container">
                    <div className="info">
                      <div className="title">
                        <Paragraph paragraph={content.launchpadInfo[8].title} />
                      </div>
                      <div className="description">
                        {content.launchpadInfo[8].description[0]}
                      </div>
                    </div>
                    <div className="image_container">
                      <img
                        src={content.launchpadImages[8].fields.file.url}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <HeroFooter
              text={content.heroFooterText}
              image={
                windowWidth <= breakpoint
                  ? content.heroFooterMobile
                  : content.heroFooterDesktop
              }
            />
            <MoreTechnologyTiles
              images={content.relatedTechnology}
              info={content.relatedTechnologyInfo}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Launchpad;
