import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

import './NewsTile.scss';

interface INewsTileProps {
  image: string;
  title: string;
  urlSlug: string;
  datePublished: string;
}

const NewsTile: React.FC<INewsTileProps> = ({
  image,
  title,
  urlSlug,
  datePublished
}) => {
  return (
    <div className="news_tile_container">
      <Link to={`/news/${urlSlug}`}>
        <img src={image} alt="" />
        <div className="description">
          {title}
          <div className="redirect">
            <div className="date_published">{datePublished}</div>
            <FontAwesomeIcon
              icon={faArrowAltCircleRight}
              size="1x"
              className="icon"
            />
            More Info
          </div>
        </div>
      </Link>
    </div>
  );
};

export default NewsTile;
