import HomePage from './pages/Home';
import AboutPage from './pages/About';
import AlexiaPage from './pages/Alexia';
import DataSolutionsPage from './pages/DataSolutions';
import VendorataManagerPage from './pages/VendorDataManager';
import LeadershipPage from './pages/Leadership';
import TechnologyPage from './pages/Technology';
import ContactPage from './pages/Contact';
import LaunchpadPage from './pages/Launchpad';
import DigitalMarketingPage from './pages/DigitalMarketing';
import Careers from './pages/Careers';
import Creative from './pages/Creative';
import News from './pages/News';
import Causes from './pages/Causes';
import Wordpress from './pages/Wordpress';

import legacyRoutes from './legacy-routes';

export const routes = [
  {
    exact: true,
    path: '/',
    component: HomePage
  },
  {
    exact: true,
    path: '/about',
    component: AboutPage
  },
  {
    exact: true,
    path: '/technology/alexia',
    component: AlexiaPage
  },
  {
    exact: true,
    path: '/technology/data-solutions',
    component: DataSolutionsPage
  },
  {
    exact: true,
    path: '/technology/vendor-data-manager',
    component: VendorataManagerPage
  },
  {
    exact: true,
    path: '/technology/launchpad',
    component: LaunchpadPage
  },
  {
    exact: true,
    path: '/technology',
    component: TechnologyPage
  },
  {
    exact: true,
    path: '/leadership',
    component: LeadershipPage
  },
  {
    exact: true,
    path: '/contact',
    component: ContactPage
  },
  {
    exact: true,
    path: '/digital-marketing',
    component: DigitalMarketingPage
  },
  {
    exact: true,
    path: '/careers',
    component: Careers
  },
  {
    exact: true,
    path: '/creative',
    component: Creative
  },
  {
    exact: true,
    path: '/news',
    component: News
  },
  {
    exact: true,
    path: '/causes',
    component: Causes
  },
  {
    exact: false,
    legacy: false,
    path: '/news/:id',
    component: Wordpress,
    pageTitle: 'NEWS'
  },
  {
    exact: false,
    legacy: false,
    path: '/careers/:id',
    component: Wordpress,
    pageTitle: 'CAREERS'
  },
  {
    exact: true,
    legacy: true,
    legacyRoutes,
    component: Wordpress
  }
];
