const legacyRoutes = [
  'https://constellationagency.com/new',
  'https://constellationagency.com/gamma',
  'https://constellationagency.com/launchpad-privacy-policy',
  'https://constellationagency.com/alexia-privacy-policy',
  'https://constellationagency.com/service-agreement',
  'https://constellationagency.com/welcome',
  'https://constellationagency.com/team-site',
  'https://constellationagency.com/2018',
  'https://constellationagency.com/news/2018/3/29/facebook-partner-category-announcement',
  'https://constellationagency.com/news/volkswagen-dealer-digital-program',
  'https://constellationagency.com/news/diversity-marketing-campaigns-for-automotive',
  'https://constellationagency.com/news/2018/6/12/third-party-data-targeting',
  'https://constellationagency.com/news/2018/8/8/women-in-business',
  'https://constellationagency.com/news/2018/9/18/constellation-agency-launches-womens-empowerment-initiative',
  'https://constellationagency.com/news/2018/9/28/constellation-agency-named-elite-marketing-partner-by-oracle',
  'https://constellationagency.com/news/2018/10/30/constellation-agency-named-certified-digital-provider-for-jaguar-land-rover-north-america',
  'https://constellationagency.com/news/2018/12/3/constellation-agency-founder-diana-lee-featured-in-forbes',
  'https://constellationagency.com/news/2019/1/18/the-power-of-millennial-talent',
  'https://constellationagency.com/news/2019/2/28/constellation-quoted-in-automotive-news-article-on-facebooks-new-automotive-inventory-ads-for-prospecting',
  'https://constellationagency.com/news/2019/3/29/foodtown-names-constellation-as-digital-agency-of-record',
  'https://constellationagency.com/news/2019/5/22/automotive-news-leading-women-conference-dallas',
  'https://constellationagency.com/news/2019/8/14/constellation-agency-named-certified-digital-provider-for-bmw-and-mini-of-north-america',
  'https://constellationagency.com/news/2019/9/13/featuredinforbes',
  'https://constellationagency.com/news/2019/10/16/automotive-news-leading-women-conference-in-detroit',
  'https://constellationagency.com/news/automotive-news-leading-women-detroit',
  'https://constellationagency.com/news/constellation-agencys-diana-lee-selected-for-competitive-ey-enterpreneurial-winning-women-class-of-2019',
  'https://constellationagency.com/news/2020/1/27/constellation-agencys-diana-lee-on-the-21-hats-podcast',
  'https://constellationagency.com/news/2020/3/2/diana-lee-authors-op-ed-for-automotive-news-about-broader-impacts-of-california-consumer-protection-act',
  'https://constellationagency.com/news/2020/4/7/car-dealers-in-nj-ny-and-texas-band-together-to-provide-help-during-coronavirus-pandemic',
  'https://constellationagency.com/news/2020/4/27/the-do-good-auto-coalition-with-ceo-diana-lee',
  'https://constellationagency.com/news/2020/5/13/constellation-agency-named-certified-digital-provider-for-porsche-cars-north-america',
  'https://constellationagency.com/news/2020/7/7/diana-lee-authors-op-ed-for-adage-about-industry-paradigm-shift-post-covid',
  'https://constellationagency.com/news/2020/7/27/food-aids-secret-weapon-data-powered-by-constellation-agency',
  'https://constellationagency.com/news/2020/8/7/constellation-joins-city-of-newark-in-allyship-panel-with-3af',
  'https://constellationagency.com/managed-services-old',
  'https://constellationagency.com/test_page-1',
  'https://constellationagency.com/contact-1',
  'https://constellationagency.com/home',
  'https://constellationagency.com/launchpad-archive',
  'https://constellationagency.com/managed-services',
  'https://constellationagency.com/portfolio-tier-two',
  'https://constellationagency.com/portfolio-jlr',
  'https://constellationagency.com/portfolio-fixed-ops',
  'https://constellationagency.com/multicultural-portfolio',
  'https://constellationagency.com/enterprise-adtech',
  'https://constellationagency.com/ccpa',
  'https://constellationagency.com/custom-creative',
  'https://constellationagency.com/payment-complete',
  'https://constellationagency.com/shop/cart',
  'https://constellationagency.com/cart.cancelled',
  'https://constellationagency.com/cart.success',
  'https://constellationagency.com/shop/cart-cancelled',
  'https://constellationagency.com/shop/cart-success',
  'https://constellationagency.com/inventory-feeds',
  'https://constellationagency.com/alexia',
  'https://constellationagency.com/thankyou',
  'https://constellationagency.com/backup',
  'https://constellationagency.com/sisense-privacy-policy',
  'https://constellationagency.com/dashboardsv2',
  'https://constellationagency.com/404',
  'https://constellationagency.com/launchpad/termsconditions',
  'https://constellationagency.com/block3',
  'https://constellationagency.com/constellationatnada'
];

export default legacyRoutes;
