import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { getFooterEntry } from './../utils/contentful';
import { IFooterContent } from './../interfaces/footer';

import CustomButton from './CustomButton';
import './Footer.scss';

interface IFooter {
  shouldDisplay?: boolean;
  updateHomeSlide?: (slide: number) => void;
}

const Footer: React.FC<IFooter> = ({ shouldDisplay, updateHomeSlide }) => {
  const [content, setContent] = useState<IFooterContent>();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>();
  const [display, setDisplay] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const breakpoint = 740; // mobile breakpoint
  const hash = useLocation().hash;

  useEffect(() => {
    const getPageContent = async () => {
      const pageContent = await getFooterEntry();
      setContent(pageContent);
    };
    getPageContent();
    setCurrentPath(location.pathname);
    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    setDisplay((currentPath === '/' && shouldDisplay) || currentPath !== '/');

    if (hash && hash === '#contact') {
      setTimeout(() => {
        const element = document.getElementById(hash.replace('#', ''));
        window.scrollTo({
          behavior: element ? 'smooth' : 'smooth',
          top: element ? element.offsetTop : 0
        });
      }, 100);
    }

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [location, currentPath, shouldDisplay, hash]);

  const handleOnBackToTop = () => {
    if (updateHomeSlide) updateHomeSlide(0);
  };

  return (
    <div>
      {content && display && (
        <div className="footer_section">
          <div
            className={
              currentPath === '/'
                ? 'let_work_together_section'
                : 'lets_work_together_flex add_padding'
            }
          >
            <div className="text">Let’s work together</div>
            <div className="work_button">
              <CustomButton
                text="CONTACT US"
                link={
                  currentPath === '/contact' ? '/contact#contact' : '/contact'
                }
              />
              {windowWidth <= breakpoint && currentPath === '/' && (
                <CustomButton
                  text="BACK TO TOP"
                  link="/"
                  styles={{ marginTop: '2%' }}
                  type="submit"
                  onClick={handleOnBackToTop}
                />
              )}
            </div>
          </div>
          <div
            className={`footer_menu_section ${
              currentPath === '/' && 'add_padding'
            }`}
          >
            {windowWidth > breakpoint && (
              <div className="constellation_3d_logo_container">
                <img src={content.constellationLogo3D.fields.file.url} alt="" />
              </div>
            )}
            <div className="menu_text_container">
              <div className="individual_menu">
                <div className="title"> {content.explore.title} </div>
                {content.explore.textList.map(({ title, link }, idx) => (
                  <div className="text" key={idx}>
                    <Link to={link}>{title}</Link>
                  </div>
                ))}
              </div>
              <div className="individual_menu">
                <div className="title"> {content.capabilities.title} </div>
                {content.capabilities.textList.map(({ title, link }, idx) => (
                  <div className="text" key={idx}>
                    <Link to={link}>{title}</Link>
                  </div>
                ))}
              </div>
              <div className="individual_menu">
                <div className="title"> {content.connect.title} </div>
                {content.connect.textList.map(({ title, link }, idx) => (
                  <div className="text" key={idx}>
                    <Link to={link}>{title}</Link>
                  </div>
                ))}
                <div className="social_media_icons">
                  {content.socialMediaIcons.map((icon, idx) => (
                    <div className="icon_container" key={idx}>
                      <a
                        href={icon.fields.description}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={icon.fields.file.url}
                          alt={icon.fields.title}
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              {windowWidth <= breakpoint && (
                <div className="constellation_2d_logo_container">
                  <img
                    src={content.constellationLogo2d.fields.file.url}
                    alt=""
                  />
                </div>
              )}
            </div>
            {windowWidth > breakpoint && (
              <div className="constellation_2d_logo_container">
                <img src={content.constellationLogo2d.fields.file.url} alt="" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
