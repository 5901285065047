import React, { useState, useEffect } from 'react';
import Title from '../components/Title';
import { ICausesContent } from '../interfaces/causes';
import { getCausesEntry } from '../utils/contentful';
import GridHorizontal from '../components/GridHorizontal';

import './Causes.scss';
import Paragraph from '../components/Paragraph';
const Causes = () => {
  const [content, setContent] = useState<ICausesContent>();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getPageContent = async () => {
      const pageContent = await getCausesEntry();
      setContent(pageContent);
    };
    getPageContent();
  }, []);
  return (
    <div>
      {content && (
        <div className="causes_page_container">
          <Title title="CAUSES" />
          <div className="intro_wrapper">
            <Paragraph paragraph={content.paragraphOne} />
          </div>
          <div className="img_wrapper">
            <img
              src={content.introImage.fields.file.url}
              alt=""
              className="intro_img_desktop"
            />
            <img
              src={content.introImageMobile.fields.file.url}
              alt=""
              className="intro_img_mobile"
            />
          </div>

          <div className="company_wrapper">
            <a href="https://dogoodautocoalition.org" className="logo_wrapper">
              <div className="logo_img">
                <img src={content.dgacLogo.fields.file.url} alt="" />
              </div>
            </a>
            <div className="text">
              <div className="title_wrapper">
                <div className="gold_rectangle"></div>
                <p className="company_title"> Do Good Auto Coalition</p>
              </div>
              <Paragraph paragraph={content.paragraphTwo} />
            </div>
          </div>
          <div className="gray_line_divider"></div>
          <div className="regular_paragraph_content">
            <Paragraph paragraph={content.paragraphThree} />
            <p className="learn_more">
              {`${content.learnMoreText} `}
              <a href="https://dogoodautocoalition.org">
                <span>dogoodautocoalition.com</span>.
              </a>
            </p>
            <GridHorizontal featuredImages={content.dgacSetImagesOne} />
          </div>
          <div className="company_wrapper">
            <a href="http://ccannj.com/st_rocco.php" className="logo_wrapper">
              <div className="logo_img">
                <img src={content.stRoccoLogo.fields.file.url} alt="" />
              </div>
            </a>
            <div className="text">
              <div className="title_wrapper">
                <div className="gold_rectangle"></div>
                <p className="company_title"> St Roccos's Family Shelter</p>
              </div>
              <Paragraph paragraph={content.paragraphThree} />
            </div>
          </div>
          <div className="gray_line_divider"></div>
          <div className="regular_paragraph_content">
            <Paragraph paragraph={content.paragraphFour} />
            <p className="learn_more">
              {`${content.learnMoreText} `}
              <a href="http://ccannj.com/st_rocco.php">
                <span>ccannj.com/st_rocco.php</span>.
              </a>
            </p>
            <GridHorizontal featuredImages={content.dgacSetImagesTwo} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Causes;
