import React from 'react';
import GoogleMapReact from 'google-map-react';

interface IGoogleMaps {
  latitude: number;
  longitude: number;
  label: string;
}

const GoogleMaps: React.FC<IGoogleMaps> = ({ latitude, longitude, label }) => {
  const renderMarkers = (map: any, maps: any) => {
    const marker = new maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
      label: {
        text: label,
        color: '#FF0000'
      }
    });
    return marker;
  };

  return (
    <div style={{ height: '50vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY! }}
        defaultCenter={{ lat: latitude, lng: longitude }}
        defaultZoom={14.5}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      />
    </div>
  );
};

export default GoogleMaps;
