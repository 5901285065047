import React from 'react';
import ReactPlayer from 'react-player';
// import { Link } from 'react-router-dom';
import CustomButton from './../components/CustomButton';
import './VideoSwap.scss';
import { IImages } from '../interfaces/general';

export interface IVideoSwapProps {
  capabilitiesVideos: IImages;
}

const VideoSwap: React.FC<IVideoSwapProps> = ({ capabilitiesVideos }) => {
  // const [showFirstVideo, setFirstVideo] = React.useState(true);
  const showFirstVideo = true;
  return (
    <div className="videos_wrapper">
      {/* <button className="tab" onClick={() => setFirstVideo(!showFirstVideo)}>
        {' '}
        TRY IT OUT
      </button> */}
      <div className="tab">
        <CustomButton link="/technology/alexia" text="LEARN MORE" />
      </div>

      {showFirstVideo ? (
        <div className="single_video">
          <ReactPlayer
            url={capabilitiesVideos[0].fields.file.url}
            playing={true}
            loop={true}
            controls={false}
            volume={0}
            muted={true}
            width="100%"
            height="auto"
            playsinline={true}
          />
        </div>
      ) : (
        <div className="single_video">
          <ReactPlayer
            url={capabilitiesVideos[1].fields.file.url}
            playing={true}
            loop={true}
            controls={false}
            volume={0}
            muted={true}
            width="100%"
            height="auto"
          />
        </div>
      )}
    </div>
  );
};

export default VideoSwap;
