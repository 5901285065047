import React, { useState, useEffect } from 'react';
import { IDigitalMarketingContent } from './../interfaces/digital-marketing';
import { getDigitalMarketingEntry } from './../utils/contentful';

import Title from './../components/Title';
import Header1 from './../components/Header1';
import Paragraph from './../components/Paragraph';
import CustomButtom from './../components/CustomButton';
import ReactPlayer from 'react-player';
import MetaData from '../components/MetaData';
import './DigitalMarketing.scss';

const DigitalMarketing: React.FC = () => {
  const [content, setContent] = useState<IDigitalMarketingContent>();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const breakpoint = 740; // mobile breakpoint;

  useEffect(() => {
    window.scrollTo(0, 0);
    const getPageContent = async () => {
      const pageContent = await getDigitalMarketingEntry();
      setContent(pageContent);
    };
    getPageContent();

    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <div>
      {content && (
        <div className="digital_marketing_page_container">
          <MetaData
            title={content.metaTitle}
            description={content.metaDescription}
            image={content.metaImage.fields.file.url}
          />
          <Title title="DIGITAL MARKETING" />
          <div className="funnel_container">
            <Paragraph paragraph={content.paragraphOne} />
            <div className="button_container">
              <CustomButtom text="LET'S TALK" link="/contact" />
            </div>
            <div className="image_container">
              <img
                src={content.icons[0].fields.file.url}
                alt=""
                className="funnel_desktop"
              />
              <img
                src={content.icons[5].fields.file.url}
                alt=""
                className="funnel_mobile"
              />
            </div>
          </div>
          <div className="our_offerings">
            <div className="golden_line" />
            <Header1 header="OUR OFFERINGS" style={{ color: '#ffffff' }} />
            <div className="info">
              <div className="text">
                <Paragraph
                  paragraph={content.ourOfferings}
                  styles={{ color: '#ffffff' }}
                />
              </div>
              <div className="icons">
                {content.icons.slice(1, 5).map((icon, idx) => (
                  <div className="icon_container" key={idx}>
                    <img src={icon.fields.file.url} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="paid_social">
            <div className="golden_line" />
            <Header1 header="PAID SOCIAL" />
            <div className="info">
              <div className="text">
                <Paragraph paragraph={content.paidSocial} />
              </div>
              <div className="image_container">
                <img src={content.paidSocialImages[0].fields.file.url} alt="" />
                <div className="pen_image_container">
                  <img
                    src={content.paidSocialImages[1].fields.file.url}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="video_wrapper">
            {windowWidth > breakpoint ? (
              <ReactPlayer
                className="player"
                url={content.digitalMarketingVideo.fields.file.url}
                playing={true}
                loop={true}
                controls={false}
                volume={0}
                muted={true}
                width="100%"
                height="auto"
              />
            ) : (
              <ReactPlayer
                className="player"
                url={content.digitalMarketingVideo.fields.file.url}
                playing={false}
                loop={false}
                controls={true}
                volume={0}
                muted={true}
                width="100%"
                height="auto"
              />
            )}
          </div>
          <div className="paid_search">
            <div className="golden_line" />
            <Header1 header="PAID SEARCH" />
            <div className="text">
              <Paragraph paragraph={content.paidSearch} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DigitalMarketing;
