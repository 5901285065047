import React from 'react';
import { IImages } from './../interfaces/general';
import Header1 from './../components/Header1';
import FeaturesTile from './FeaturesTile';
import './FeaturesContainer.scss';

export interface IFeaturesContainerProps {
  title: string;
  icons: IImages;
  iconsDescription: [string];
}

const FeaturesContainer: React.FC<IFeaturesContainerProps> = ({
  icons,
  iconsDescription,
  title
}) => {
  return (
    <div className="features_container">
      <div className="above_line" />
      <div className="header_container">
        <Header1 header={title} />
      </div>
      <div className="tiles_container">
        {icons.map((icon, idx) => (
          <div className="feature_tile_container" key={idx}>
            <FeaturesTile
              icon={icon.fields.file.url}
              description={iconsDescription[idx]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesContainer;
