import { createClient } from 'contentful';
import { IAboutContent } from '../interfaces/about';
import { INavBarContent } from '../interfaces/navbar';
import { IHomeContent } from '../interfaces/home';
import { IAlexiaContent } from '../interfaces/alexia';
import { IDataSolutionsContent } from '../interfaces/data-solutions';
import { IVendorDataManagerContent } from '../interfaces/vendor-data-manager';
import { ITechnologyContent } from '../interfaces/technology';
import { IFooterContent } from '../interfaces/footer';
import { ILeadershipContent } from '../interfaces/leadership';
import { IContactContent } from '../interfaces/contact';
import { ILaunchpadContent } from '../interfaces/launchpad';
import { IDigitalMarketingContent } from '../interfaces/digital-marketing';
import { ICareersContent } from '../interfaces/careers';
import { ICreativeContent } from '../interfaces/creative';
import { ICausesContent } from '../interfaces/causes';

require('dotenv').config();

const ACCESS_TOKEN: string = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN!;
const SPACE_ID: string = process.env.REACT_APP_CONTENTFUL_SPACE_ID!;
const ABOUT_ID: string = process.env.REACT_APP_CONTENTFUL_ABOUT_ID!;
const NAVBAR_ID: string = process.env.REACT_APP_CONTENTFUL_NAVBAR_ID!;
const HOME_ID: string = process.env.REACT_APP_CONTENTFUL_HOME_ID!;
const ALEXIA_ID: string = process.env.REACT_APP_CONTENTFUL_ALEXIA_ID!;
const DATA_SOLUTIONS_ID: string = process.env
  .REACT_APP_CONTENTFUL_DATA_SOLUTIONS_ID!;
const VENDOR_DATA_MANAGER_ID: string = process.env
  .REACT_APP_CONTENTFUL_VENDOR_DATA_MANAGER_ID!;
const TECH_ID: string = process.env.REACT_APP_CONTENTFUL_TECHNOLOGY_ID!;
const FOOTER_ID: string = process.env.REACT_APP_CONTENTFUL_FOOTER_ID!;
const LEADERSHIP_ID: string = process.env.REACT_APP_CONTENTFUL_LEADERSHIP_ID!;
const CONTACT_ID: string = process.env.REACT_APP_CONTENTFUL_CONTACT_ID!;
const LAUNCHPAD_ID: string = process.env.REACT_APP_CONTENTFUL_LAUNCHPAD_ID!;
const DIGITAL_MARKETING: string = process.env
  .REACT_APP_CONTENTFUL_DIGITAL_MARKETING!;
const CAREER_ID: string = process.env.REACT_APP_CONTENFUL_CAREERS_ID!;
const CREATIVE_ID: string = process.env.REACT_APP_CONTENFUL_CREATIVE_ID!;
const CAUSES_ID: string = process.env.REACT_APP_CONTENFUL_CAUSES_ID!;

const getClient = async () => {
  return createClient({
    space: SPACE_ID,
    accessToken: ACCESS_TOKEN
  });
};

const getEntryIdToken = (id: string): string => {
  switch (id) {
    case 'digitalMarketing':
      return DIGITAL_MARKETING;
    case 'launchpad':
      return LAUNCHPAD_ID;
    case 'contact':
      return CONTACT_ID;
    case 'leadership':
      return LEADERSHIP_ID;
    case 'alexia':
      return ALEXIA_ID;
    case 'dataSolutions':
      return DATA_SOLUTIONS_ID;
    case 'vendorDataManager':
      return VENDOR_DATA_MANAGER_ID;
    case 'home':
      return HOME_ID;
    case 'about':
      return ABOUT_ID;
    case 'navbar':
      return NAVBAR_ID;
    case 'technology':
      return TECH_ID;
    case 'footer':
      return FOOTER_ID;
    case 'careers':
      return CAREER_ID;
    case 'creative':
      return CREATIVE_ID;
    case 'causes':
      return CAUSES_ID;
    default:
      return '';
  }
};

const getEntry = async (id: string) => {
  const idToken = getEntryIdToken(id);
  const client = await getClient();
  return (await client.getEntry(idToken)).fields;
};

export const getAboutEntry = async () => {
  return getEntry('about') as Promise<IAboutContent>;
};

export const getNavBarEntry = async () => {
  return getEntry('navbar') as Promise<INavBarContent>;
};

export const getHomeEntry = async () => {
  return getEntry('home') as Promise<IHomeContent>;
};

export const getAlexiaEntry = async () => {
  return getEntry('alexia') as Promise<IAlexiaContent>;
};

export const getDataSolutionsEntry = async () => {
  return getEntry('dataSolutions') as Promise<IDataSolutionsContent>;
};

export const getVendorDataManagerEntry = async () => {
  return getEntry('vendorDataManager') as Promise<IVendorDataManagerContent>;
};

export const getTechnologyEntry = async () => {
  return getEntry('technology') as Promise<ITechnologyContent>;
};

export const getFooterEntry = async () => {
  return getEntry('footer') as Promise<IFooterContent>;
};

export const getLeadershipEntry = async () => {
  return getEntry('leadership') as Promise<ILeadershipContent>;
};

export const getContactEntry = async () => {
  return getEntry('contact') as Promise<IContactContent>;
};

export const getLaunchpadEntry = async () => {
  return getEntry('launchpad') as Promise<ILaunchpadContent>;
};

export const getDigitalMarketingEntry = async () => {
  return getEntry('digitalMarketing') as Promise<IDigitalMarketingContent>;
};

export const getCareersEntry = async () => {
  return getEntry('careers') as Promise<ICareersContent>;
};

export const getCreativeEntry = async () => {
  return getEntry('creative') as Promise<ICreativeContent>;
};

export const getCausesEntry = async () => {
  return getEntry('causes') as Promise<ICausesContent>;
};
