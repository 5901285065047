import React from 'react';

import './EmployessTile.scss';

export interface IEmployessTileProps {
  name: string;
  position: string;
  image: string;
  windowWidth: number;
  breakpoint: number;
}

const EmployessTile: React.FC<IEmployessTileProps> = ({
  name,
  position,
  image,
  windowWidth,
  breakpoint
}) => {
  return (
    <div>
      <div className="employee_image_container">
        <img src={image} alt={name} />
        {windowWidth > breakpoint && (
          <div className="employee_caption">
            <div className="employee_name">{name}</div>
            <div className="employee_position"> {position} </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployessTile;
