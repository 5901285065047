import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import './JobPosting.scss';

export interface IJobPostingProps {
  title: string;
  urlSlug: string;
}

const JobPosting: React.FC<IJobPostingProps> = ({ title, urlSlug }) => {
  return (
    <div className="job_posting_tile">
      <p className="job_title">{title}</p>
      <div className="link_wrapper">
        <FontAwesomeIcon
          icon={faArrowAltCircleRight}
          size="1x"
          className="icon"
        />
        <Link to={`/careers/${urlSlug}`}>View this role</Link>
      </div>
    </div>
  );
};

export default JobPosting;
