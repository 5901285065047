import React from 'react';
import { Link } from 'react-router-dom';
import './CustomButton.scss';
export interface ICustomButtonProps {
  link: string;
  text: string;
  styles?: React.CSSProperties;
  mail?: boolean;
  type?: string;
  onClick?: () => void;
  homepage?: boolean;
}

const CustomButton: React.FC<ICustomButtonProps> = ({
  link,
  text,
  styles,
  mail,
  type,
  onClick,
  homepage
}) => {
  return (
    <div>
      {mail ? (
        <a href={link} className="custom_button_container">
          <button style={styles}> {text} </button>
        </a>
      ) : type === 'submit' ? (
        <div className="custom_button_container">
          <button
            style={styles}
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              if (onClick) onClick();
            }}
          >
            {text}
          </button>
        </div>
      ) : (
        <Link
          to={link}
          className="custom_button_container"
          style={homepage ? { display: 'block', justifyContent: 'none' } : {}}
        >
          <button style={styles}> {text} </button>
        </Link>
      )}
    </div>
  );
};

export default CustomButton;
