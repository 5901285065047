import React, { useState, useEffect } from 'react';
import { IAlexiaContent } from './../interfaces/alexia';
import { getAlexiaEntry } from './../utils/contentful';
import Title from './../components/Title';
import Paragraph from './../components/Paragraph';
import FeaturesContainer from './../components/FeaturesContainer';
import Header1 from '../components/Header1';
import HeroFooter from '../components/TechnologyHeroFooter';
import MoreTechnologyTiles from '../components/MoreTechnologyTile';
import MetaData from '../components/MetaData';
import './Alexia.scss';

const Alexia: React.FC = () => {
  const [content, setContent] = useState<IAlexiaContent>();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const breakpoint = 740; // mobile breakpoint

  useEffect(() => {
    window.scrollTo(0, 0);
    const getPageContent = async () => {
      const pageContent = await getAlexiaEntry();
      setContent(pageContent);
    };
    getPageContent();

    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <div>
      {content && (
        <div className="alexia_page_container">
          <MetaData
            title={content.metaTitle}
            description={content.metaDescription}
            image={content.metaImage.fields.file.url}
          />
          <Title title="ALEXIA" />
          <div className="automation_section">
            <Paragraph paragraph={content.paragraphOne} />
            <div className="automation_image_section">
              <div className="description">The automation of Alexia</div>
              {windowWidth <= breakpoint ? (
                <div className="image_container">
                  <img
                    src={content.automationImageMobile.fields.file.url}
                    alt=""
                  />
                </div>
              ) : (
                <div className="image_container">
                  <img src={content.automationImage.fields.file.url} alt="" />
                </div>
              )}
            </div>
            <Paragraph paragraph={content.paragraphTwo} />
          </div>
          <FeaturesContainer
            title="FEATURES"
            icons={content.featureIcons}
            iconsDescription={content.featuresInfo}
          />
          <div className="automation_graphics_container">
            <div className="above_line" />
            <div className="header_container">
              <Header1 header="AUTOMATION" style={{ color: '#ffffff' }} />
            </div>
            <div className="automation_paragraph">
              <Paragraph
                paragraph={content.automationText}
                styles={{ color: '#ffffff' }}
              />
            </div>
            {windowWidth <= breakpoint ? (
              <div className="graphics_section">
                <div className="graphic_container">
                  <img
                    src={content.automationGraphsMobile[0].fields.file.url}
                    alt=""
                  />
                </div>
                <div className="graphic_container">
                  <img
                    src={content.automationGraphsMobile[1].fields.file.url}
                    alt=""
                  />
                </div>
                <div className="graphic_container">
                  <img
                    src={content.automationGraphsMobile[2].fields.file.url}
                    alt=""
                  />
                </div>
              </div>
            ) : (
              <div className="graphics_section">
                <div className="graphic_container">
                  <img
                    src={content.automationGraphs[0].fields.file.url}
                    alt=""
                  />
                </div>
                <div className="graphic_container">
                  <img
                    src={content.automationGraphs[1].fields.file.url}
                    alt=""
                  />
                </div>
                <div className="graphic_container">
                  <img
                    src={content.automationGraphs[2].fields.file.url}
                    alt=""
                  />
                </div>
              </div>
            )}
          </div>
          <HeroFooter
            image={
              windowWidth <= breakpoint
                ? content.heroFooterMobile
                : content.heroFooterDesktop
            }
            text={content.heroFooterText}
          />
          <MoreTechnologyTiles
            images={content.relatedTechnology}
            info={content.relatedTechnologyInfo}
          />
        </div>
      )}
    </div>
  );
};

export default Alexia;
