import React from 'react';
import { IImageUrl } from '../interfaces/general';
import Paragraph from '../components/Paragraph';
import CustomButton from '../components/CustomButton';

import './TechnologyHeroFooter.scss';

export interface ITechnologyHeroFooterProps {
  text: string;
  image: IImageUrl;
  infoStyling?: React.CSSProperties;
}

const TechnologyHeroFooter: React.FC<ITechnologyHeroFooterProps> = ({
  text,
  image,
  infoStyling
}) => {
  return (
    <div className="technology_hero_footer_section">
      <div className="hero_image_container">
        <img src={image.fields.file.url} alt="" />
        <div className="footer_hero_info" style={infoStyling}>
          <Paragraph paragraph={text} />
          <CustomButton
            text="REQUEST DEMO"
            link="/contact"
          />
        </div>
      </div>
    </div>
  );
};

export default TechnologyHeroFooter;
