import React from 'react';
import './Header1.scss';

export interface IHeader1Props {
  header: string;
  style?: React.CSSProperties;
}

const Header1: React.FC<IHeader1Props> = ({ header, style }) => {
  return (
    <div className="header_one_container">
      <h1 style={style}> {header} </h1>
    </div>
  );
};

export default Header1;
