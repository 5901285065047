import React from 'react';
import './FeaturesTile.scss';
export interface IFeaturesTileProps {
  icon: string;
  description: string;
}

const FeaturesTile: React.FC<IFeaturesTileProps> = ({ icon, description }) => {
  return (
    <div className="features_tile_container">
      <div className="icon_container">
        <img src={icon} alt="" />
      </div>
      <div className="description"> {description} </div>
    </div>
  );
};

export default FeaturesTile;
