import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import NavigationMenu from './NavigationMenu';
import { INavBarContent } from '../interfaces/navbar';
import { getNavBarEntry } from './../utils/contentful';

import './NavBar.scss';

const NavBar: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>();
  const [content, setContent] = useState<INavBarContent>();
  const [isMenuClicked, setIsMenuClicked] = useState<boolean>(false);
  const breakpoint = 740; // mobile breakpoint

  useEffect(() => {
    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    const getPageContent = async () => {
      const pageContent = await getNavBarEntry();
      setContent(pageContent);
    };
    setCurrentPath(location.pathname);
    getPageContent();
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [location, currentPath]);

  return (
    <div>
      {content && (
        <div
          className="navbar_container"
          style={
            currentPath === '/'
              ? {
                  backgroundColor: 'transparent',
                  zIndex: 2,
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%'
                }
              : {}
          }
        >
          <div className="contellation_logo_container">
            <Link to="/">
              <img
                src={content.constellationLogo.fields.file.url}
                alt="Constellation Agency Logo"
              />
            </Link>
          </div>
          <div
            className="menu_container"
            onClick={() => setIsMenuClicked(!isMenuClicked)}
          >
            <div className="hamburger_menu">
              <div className="big_line" />
              <div className="medium_line" />
              <div className="small_line" />
            </div>
            {windowWidth > breakpoint && <div className="menu_text">MENU</div>}
          </div>
        </div>
      )}
      <NavigationMenu
        setIsMenuClicked={setIsMenuClicked}
        display={isMenuClicked}
      />
    </div>
  );
};

export default NavBar;
