import React, { useState, useEffect } from 'react';
import Title from '../components/Title';
import Subtitle from '../components/Subtitle';
import { ICreativeContent } from '../interfaces/creative';
import { getCreativeEntry } from '../utils/contentful';
import Paragraph from './../components/Paragraph';
import GridScrollAnimation from '../components/GridScrollAnimation';
import VideoSwap from '../components/VideoSwap';
import ReactPlayer from 'react-player';
import MetaData from '../components/MetaData';
import './Creative.scss';

const Creative = () => {
  const [content, setContent] = useState<ICreativeContent>();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getPageContent = async () => {
      const pageContent = await getCreativeEntry();
      setContent(pageContent);
    };
    getPageContent();
  }, []);
  return (
    <div>
      {content && (
        <div className="creative_page_container">
          <MetaData
            title={content.metaTitle}
            description={content.metaDescription}
            image={content.metaImage.fields.file.url}
          />
          <Title title="CREATIVE" />
          <div className="intro_wrapper">
            <Paragraph paragraph={content.intro} />
          </div>
          <div className="grid_animated_wrapper">
            <GridScrollAnimation
              column1={content.animatedGifColumn1}
              column2={content.animatedGifColumn2}
              column3={content.animatedGifColumn3}
            />
          </div>
          <div className="capabilities_wrapper">
            <Subtitle subtitle="CAPABILITIES" />
            <p className="capabilities_subtitle">
              <span className="subtitle_grey"> Social Media Assets.</span>
              <span className="subtitle_navy"> Display Assets.</span>
              <span className="subtitle_grey"> Email templates.</span>
              <span className="subtitle_navy"> Website design and UX.</span>
              <span className="subtitle_grey"> Video Production.</span>
              <span className="subtitle_navy"> Animations</span>
            </p>
            <div className="capabilities_intro_wrapper">
              <div className="showcase_column_left">
                <Paragraph paragraph={content.capabilitiesIntro} />
                <ReactPlayer
                  className="desktop_img"
                  url={content.capabilitiesShowcaseImages[0].fields.file.url}
                  playing={true}
                  loop={true}
                  controls={false}
                  volume={0}
                  muted={true}
                  width="90%"
                  height="auto"
                  playsinline={true}
                />
              </div>
              <div className="showcase_column_right">
                <ReactPlayer
                  className="desktop_img"
                  url={content.capabilitiesShowcaseImages[1].fields.file.url}
                  playing={true}
                  loop={true}
                  controls={false}
                  volume={0}
                  muted={true}
                  width="141%"
                  height="auto"
                  playsinline={true}
                />
              </div>
              <div className="mobile_video_wrapper">
                <ReactPlayer
                  url={content.capabilitiesShowcaseImages[0].fields.file.url}
                  playing={true}
                  loop={true}
                  controls={false}
                  volume={0}
                  muted={true}
                  width="100%"
                  height="auto"
                  playsinline={true}
                />
              </div>
            </div>
            <div className="second_paragraph_wrapper">
              <Paragraph paragraph={content.capabilitiesSecondParagraph} />
            </div>
          </div>
          <div className="capabilities_showcase_wrapper">
            <VideoSwap capabilitiesVideos={content.capabilitiesVideos} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Creative;
