import React from 'react';

import './Subtitle.scss';

export interface ISubtitleProps {
  subtitle: string;
}

const Subtitle: React.FC<ISubtitleProps> = ({ subtitle }) => {
  return (
    <div className="subtitle_container">
      <div className="subtitle_overline"></div>
      <p>{subtitle}</p>
    </div>
  );
};

export default Subtitle;
