import React from 'react';
import './Paragraph.scss';

export interface IParagraphProps {
  paragraph: string;
  styles?: React.CSSProperties;
}

const Paragraph: React.FC<IParagraphProps> = ({ paragraph, styles }) => {
  return (
    <p className="paragraph_style" style={styles}>
      {paragraph}
    </p>
  );
};

export default Paragraph;
