import React from 'react';
import CustomButton from './CustomButton';
import './SassOfferingsTile.scss';

export interface ISassOfferingsTileProps {
  backgroundImage?: string;
  title: string;
  text: string;
  route: string;
}

const SassOfferingsTile: React.FC<ISassOfferingsTileProps> = ({
  backgroundImage,
  text,
  title,
  route
}) => {
  return (
    <div className="sass_offerings_tile_container">
      <div className="content_container">
        <img src={backgroundImage} alt="" />
        <div className="text_info">
          <div className="title">{title.toUpperCase()}</div>
          <div className="text">{text}</div>
          <div className="button_container">
            <CustomButton text="LEARN MORE" link={route} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SassOfferingsTile;
