import React, { useState, useEffect } from 'react';
import { IDataSolutionsContent } from './../interfaces/data-solutions';
import { getDataSolutionsEntry } from './../utils/contentful';
import Title from './../components/Title';
import Paragraph from './../components/Paragraph';
import FeaturesContainer from './../components/FeaturesContainer';
import HeroFooter from '../components/TechnologyHeroFooter';
import MoreTechnologyTiles from '../components/MoreTechnologyTile';
import MetaData from '../components/MetaData';
import './DataSolutions.scss';

const DataSolutions: React.FC = () => {
  const [content, setContent] = useState<IDataSolutionsContent>();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const breakpoint = 740; // mobile breakpoint

  useEffect(() => {
    window.scrollTo(0, 0);
    const getPageContent = async () => {
      const pageContent = await getDataSolutionsEntry();
      setContent(pageContent);
    };
    getPageContent();

    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  return (
    <div>
      {content && (
        <div className="data_solutions_container">
          <MetaData
            title={content.metaTitle}
            description={content.metaDescription}
            image={content.metaImage.fields.file.url}
          />
          <Title title="DATA SOLUTIONS" />
          <div className="paragraph_container">
            <Paragraph paragraph={content.paragraphOne} />
          </div>
          <FeaturesContainer
            title="FEATURES"
            icons={content.featureIcons}
            iconsDescription={content.featuresInfo}
          />
          <div className="line_separator" />
          <div className="data_stack_section">
            <div className="description">
              <div className="stack_section">
                <div className="grey_line_separator" />
                <p className="stack_title">Sources</p>
              </div>
              <div className="stack_img_wrapper">
                <img src={content.dataStackImages[0].fields.file.url} alt="" />
              </div>
            </div>

            <div className="description">
              <div className="stack_section">
                <div className="grey_line_separator" />
                <p className="stack_title">Data Stack</p>
              </div>
              <div className="stack_img_wrapper">
                <img src={content.dataStackImages[1].fields.file.url} alt="" />
              </div>
            </div>

            <div className="description">
              <div className="stack_section">
                <div className="grey_line_separator" />
                <p className="stack_title">Intelligence</p>
              </div>
              <div className="stack_img_wrapper">
                <img src={content.dataStackImages[2].fields.file.url} alt="" />
              </div>
            </div>
          </div>
          <div className="data_stack_section_mobile">
            <img src="" alt="" />
          </div>
          <HeroFooter
            text={content.heroFooterText}
            image={
              windowWidth <= breakpoint
                ? content.heroFooterMobile
                : content.heroFooterDesktop
            }
            infoStyling={{ border: 'solid 20px rgba(255, 255, 255, 0.3)' }}
          />
          <MoreTechnologyTiles
            images={content.relatedTechnology}
            info={content.relatedTechnologyInfo}
          />
        </div>
      )}
    </div>
  );
};

export default DataSolutions;
