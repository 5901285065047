import React, { useState, useEffect } from 'react';
import { ISlidesInfoContent } from '../interfaces/home';
import { IImageUrl } from '../interfaces/general';
import { Fade } from 'react-reveal';
import ReactPlayer from 'react-player';
import CustomButtom from './CustomButton';

import './GenericHomepageSlides.scss';

interface IGenericHomepageSlidesProps {
  videoSlide: IImageUrl;
  slideInfo: ISlidesInfoContent;
  displayOverlay: boolean;
  textAnimation: IImageUrl;
  setCurrentPage: (currentPage: number) => void;
  stillImage: IImageUrl;
}

const GenericHomepageSlides: React.FC<IGenericHomepageSlidesProps> = ({
  videoSlide,
  slideInfo,
  displayOverlay,
  textAnimation,
  setCurrentPage,
  stillImage
}) => {
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  const [videoHeight, setVideoHeight] = useState<number>(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const breakpoint = 740; // mobile breakpoint
  const [videoError, setVideoError] = useState<boolean>(false);
  const [isVideoready, setisVideoReady] = useState<boolean>(false);

  useEffect(() => {
    const handleWindowResize = () => {
      if (isBreakpointHeight(window.innerHeight) && window.innerHeight > 650) {
        setVideoHeight(window.innerHeight + 100);
      }
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const isBreakpointHeight = (height: number): boolean => {
    return height % 50 === 0;
  };

  return (
    <div className="generic_homepage_slides_container">
      <div
        className="video_container"
        style={displayOverlay ? {} : { background: 'none' }}
      >
        {!videoError ? (
          <div>
            <ReactPlayer
              className="player"
              url={videoSlide.fields.file.url}
              playing={true}
              loop={true}
              controls={false}
              volume={0}
              muted={true}
              width="auto"
              height={windowWidth <= breakpoint ? '100vh' : videoHeight}
              playsinline={true}
              onError={() => setVideoError(true)}
              onReady={() => setisVideoReady(true)}
              style={isVideoready ? {} : { display: 'none' }}
            />
            {!isVideoready && windowWidth <= breakpoint && (
              <div className="player">
                <img
                  src={stillImage.fields.file.url}
                  alt={stillImage.fields.title}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="player">
            <img
              src={stillImage.fields.file.url}
              alt={stillImage.fields.title}
            />
          </div>
        )}
        <div
          className="info_container"
          style={
            windowWidth <= breakpoint
              ? { top: `${windowHeight / 2.3}px` }
              : { top: `${windowHeight / 3}px` }
          }
        >
          <Fade bottom={true}>
            <div className="title">{slideInfo.title.toUpperCase()}</div>
          </Fade>
          <Fade bottom={true} delay={500}>
            <div className="description">{slideInfo.description}</div>

            {slideInfo.link && !slideInfo.newsLink ? (
              <CustomButtom
                text="LEARN MORE"
                link={slideInfo.link}
                styles={{ width: '70%' }}
                homepage={true}
              />
            ) : (
              slideInfo.link &&
              slideInfo.newsLink && (
                <div className="news_buttons_content">
                  <CustomButtom
                    text="READ ARTICLE"
                    link={slideInfo.newsLink}
                    styles={{ width: '70%' }}
                    homepage={true}
                  />
                  <div className="button_spacer" />
                  <CustomButtom
                    text="SEE ALL"
                    link={slideInfo.link}
                    styles={{ width: '70%' }}
                    homepage={true}
                  />
                </div>
              )
            )}
          </Fade>
        </div>

        <div
          className="text_animation"
          onClick={() =>
            // tslint:disable-next-line: radix
            setCurrentPage(parseInt(textAnimation.fields.description))
          }
        >
          <Fade bottom={true} delay={1000}>
            <img
              src={textAnimation.fields.file.url}
              alt={textAnimation.fields.title}
              style={windowWidth <= breakpoint ? { bottom: '5%' } : {}}
            />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default GenericHomepageSlides;
