import React from 'react';
import './GridHorizontal.scss';
import { IImages } from '../interfaces/general';

export interface IGridHorizontalProps {
  featuredImages: IImages;
}

const GridHorizontal: React.FC<IGridHorizontalProps> = ({ featuredImages }) => {
  return (
    <div className="grid_horizontal_wrapper">
      <div className="images_grid">
        {featuredImages.map((img, idx) => (
          <div className="single_img" key={idx}>
            <img src={img.fields.file.url} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GridHorizontal;
