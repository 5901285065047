import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Title from './../components/Title';
import ReactPlayer from 'react-player';
import Header1 from './../components/Header1';
import Paragraph from './../components/Paragraph';
import LeadershipTile from './../components/LeadershipTile';
import EmployeeTile from './../components/EmployeesTile';
import CustomButtom from './../components/CustomButton';
import { getAboutEntry } from './../utils/contentful';
import { IAboutContent } from '../interfaces/about';
import MetaData from '../components/MetaData';
import './About.scss';

const About: React.FC = () => {
  const [content, setContent] = useState<IAboutContent>();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const breakpoint = 740; // mobile breakpoint
  const [historyImagesIndex, setHistoryImagesIndex] = useState<number>(0);
  const hash = useLocation().hash;

  useEffect(() => {
    window.scrollTo(0, 0);
    const getPageContent = async () => {
      const pageContent = await getAboutEntry();
      setContent(pageContent);
    };
    getPageContent();

    if (hash && hash === '#leadership') {
      setTimeout(() => {
        const element = document.getElementById(hash.replace('#', ''));
        window.scrollTo({
          behavior: element ? 'smooth' : 'auto',
          top: element ? element.offsetTop : 0
        });
      }, 100);
    }

    const handleWindowResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {content && (
        <div className="about_page_container">
          <MetaData
            title={content.metaTitle}
            description={content.metaDescription}
            image={content.metaImage.fields.file.url}
          />
          <Title title="ABOUT US" />
          <div className="about_page_content">
            <div className="our_approach_section">
              <div className="text">
                <Header1 header={content.title} />
                <Paragraph paragraph={content.paragraph_one} />
              </div>
              <div className="image_container">
                <img
                  src={content.our_approach_images[0].fields.file.url}
                  alt=""
                />
              </div>
            </div>
            <div className="image_collage_section">
              <div className="main_image_container">
                <img
                  src={content.our_approach_images[1].fields.file.url}
                  alt=""
                />
              </div>
              {windowWidth > breakpoint && (
                <div className="sub_image_container">
                  <img
                    src={content.our_approach_images[2].fields.file.url}
                    alt=""
                  />
                  <div className="bottom_images">
                    <img
                      src={content.our_approach_images[3].fields.file.url}
                      alt=""
                    />
                    <img
                      src={content.our_approach_images[4].fields.file.url}
                      alt=""
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Re-using the same css class */}
            <div className="our_approach_section reduce_margin">
              {windowWidth > breakpoint && (
                <div className="image_container">
                  <img
                    src={content.our_approach_images[5].fields.file.url}
                    alt=""
                  />
                </div>
              )}
              <div className="text">
                <Paragraph paragraph={content.paragraph_two} />
                <Paragraph paragraph={content.paragraph_three} />
              </div>
            </div>
            <div className="line_separator reduce_margin" />
            {/* ********** HISTORY SECTION **********  */}
            {windowWidth <= breakpoint && (
              <Header1 header="HISTORY" style={{ paddingLeft: '7%' }} />
            )}
            <div className="history_section">
              <div className="years_section">
                <div
                  className={`${
                    historyImagesIndex === 0 ? 'highlighted_year' : 'years'
                  }`}
                  onClick={() => setHistoryImagesIndex(0)}
                >
                  2016
                </div>
                <div
                  className={`${
                    historyImagesIndex === 1 ? 'highlighted_year' : 'years'
                  }`}
                  onClick={() => setHistoryImagesIndex(1)}
                >
                  2017
                </div>
                <div
                  className={`${
                    historyImagesIndex === 2 ? 'highlighted_year' : 'years'
                  }`}
                  onClick={() => setHistoryImagesIndex(2)}
                >
                  2018
                </div>
                <div
                  className={`${
                    historyImagesIndex === 3 ? 'highlighted_year' : 'years'
                  }`}
                  onClick={() => setHistoryImagesIndex(3)}
                >
                  2019
                </div>
                <div
                  className={`${
                    historyImagesIndex === 4 ? 'highlighted_year' : 'years'
                  }`}
                  onClick={() => setHistoryImagesIndex(4)}
                >
                  2020
                </div>
              </div>
              <div className="history_text_container">
                {windowWidth > breakpoint && <Header1 header="HISTORY" />}
                <div className="image_container">
                  <img
                    src={
                      content.historyImages[historyImagesIndex].fields.file.url
                    }
                    alt=""
                  />
                  <div className="image_caption">
                    {content.historyImagesCaption[historyImagesIndex].caption}
                  </div>
                </div>
              </div>
            </div>
            {/* ********** CULTURE SECTION **********  */}
            <div className="culture_section">
              <div className="culture_text">
                <Header1 header="CULTURE" style={{ color: '#000000' }} />
                <Paragraph paragraph={content.culture_text} />
              </div>
              <div className="culture_image_collage">
                <div className="image_row">
                  {content.culture_images
                    .slice(
                      0,
                      windowWidth > breakpoint
                        ? Math.floor(content.culture_images.length / 2)
                        : 3
                    )
                    .map((image, idx) => (
                      <div className="image_container" key={idx}>
                        <img src={image.fields.file.url} alt="" />
                      </div>
                    ))}
                </div>
                <div className="image_row">
                  {content.culture_images
                    .slice(
                      Math.floor(content.culture_images.length / 2),
                      windowWidth > breakpoint
                        ? content.culture_images.length - 1
                        : Math.floor(content.culture_images.length / 2) + 3
                    )
                    .map((image, idx) => (
                      <div className="image_container" key={idx}>
                        <img src={image.fields.file.url} alt="" />
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="culture_employess_container">
              {windowWidth > breakpoint ? (
                <ReactPlayer
                  className="player"
                  url={content.cultureVideo.fields.file.url}
                  playing={true}
                  loop={true}
                  controls={false}
                  volume={0}
                  muted={true}
                  width="100%"
                  height="auto"
                />
              ) : (
                <ReactPlayer
                  className="player"
                  url={content.cultureVideo.fields.file.url}
                  playing={false}
                  loop={false}
                  controls={true}
                  volume={0}
                  muted={true}
                  width="100%"
                  height="auto"
                />
              )}
            </div>
            {/* ********** CLIENTS & PARTNERSHIP SECTION **********  */}
            <div className="clients_and_partnerships_section">
              <div className="clients_container">
                <div className="text">
                  <div>
                    <Header1
                      header={'SELECT CLIENTS'}
                      style={{ color: '#ffffff' }}
                    />
                  </div>
                </div>
                <div className="logos">
                  <div className="logos_row">
                    {content.select_clients.map((logo, idx) => (
                      <div className="logo_container" key={idx}>
                        <img
                          src={logo.fields.file.url}
                          alt={logo.fields.title}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Re-using same css */}
              <div className="clients_container">
                <div className="text">
                  <div>
                    <Header1 header={'AWARDS &'} style={{ color: '#ffffff' }} />
                  </div>
                  <div>
                    <Header1
                      header={'CERTIFICATIONS'}
                      style={{ color: '#ffffff' }}
                    />
                  </div>
                </div>
                <div className="logos">
                  <div className="logos_row">
                    {content.partnerships_certs.map((logo, idx) => (
                      <div className="logo_container" key={idx}>
                        <img
                          src={logo.fields.file.url}
                          alt={logo.fields.title}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Re-using same css */}
              <div className="clients_container">
                <div className="text">
                  <div>
                    <Header1
                      header={'AFFILIATES'}
                      style={{ color: '#ffffff' }}
                    />
                  </div>
                </div>
                <div className="logos">
                  <div className="logos_row">
                    {content.affiliatesLogos.map((logo, idx) => (
                      <div className="logo_container" key={idx}>
                        <img
                          src={logo.fields.file.url}
                          alt={logo.fields.title}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* ********** LEADERSHIP SECTION **********  */}
            <div className="leadership_section" id="leadership">
              <div className="title">
                <Header1 header="LEADERSHIP" style={{ color: '#000000' }} />
              </div>
              <div className="leadership_container">
                {content.leadership_info.map((info, idx) => (
                  <div className="leader" key={idx}>
                    <LeadershipTile
                      name={info.name}
                      position={info.position}
                      image={content.leadership_images[idx].fields.file.url}
                      index={info.index}
                    />
                  </div>
                ))}
              </div>
            </div>
            {/* ********** TEAM SECTION **********  */}
            <div className="team_section">
              <div className="title">
                <Header1 header="TEAM" style={{ color: '#000000' }} />
              </div>
              <div className="team_container">
                {content.team_images.map((teammate, idx) => (
                  <div className="employee" key={idx}>
                    <EmployeeTile
                      name={teammate.fields.title}
                      position={teammate.fields.description}
                      image={teammate.fields.file.url}
                      windowWidth={windowWidth}
                      breakpoint={breakpoint}
                    />
                  </div>
                ))}
              </div>
            </div>
            {/* ********** CAREERS SECTION **********  */}
            <div className="careers_section">
              <div className="careers_info">
                <div className="careers_text">
                  <Header1 header="CAREERS" style={{ color: '#000000' }} />
                  <Paragraph paragraph={content.careers_text} />
                  <div className="careers_button">
                    <CustomButtom text="VIEW OPEN POSITIONS" link="/careers" />
                  </div>
                </div>
                <div className="under_career_image_container">
                  <img src={content.career_images[0].fields.file.url} alt="" />
                </div>
              </div>
              <div className="careers_image_container">
                <img src={content.career_images[1].fields.file.url} alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
